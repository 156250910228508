
const getters = {
  menuData: state => state.settings.menuData,
  researcherInfo: () => {
    let researcherInfo;
    if (process.env.NODE_ENV == "development") {
      researcherInfo = {
        t_researcher_id: 1,
        txt_researcher_name: "沈琳",
        password: "cxc123",
        txt_token: "dfe60416-736d-4326-a760-45a935834230",
        is_first_login: 1, //null 首次登录 需要提示修改密码(强制修改) 1不需要修改
        is_change: null //用于接近90天修改密码的状态 null 不需要修改 1需要提示修改密码(强制修改)
      }
    } else {
      researcherInfo = JSON.parse(sessionStorage.getItem("researcherInfo"));
    }
    return researcherInfo;
  },
  // 生成随机字符串
  UUID() { return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function () { return (Math.random() * 16 | 0).toString(16); }); },
};

export default getters;