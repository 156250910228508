import axios from "axios";


axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_COMMON_API,
  timeout: 60000
});

// request 拦截器
service.interceptors.request.use(config => {
  // 所有请求中携带token
  let researcherInfo = sessionStorage.getItem("researcherInfo");
  if (researcherInfo) {
    researcherInfo = JSON.parse(researcherInfo);
    config.headers.post['authtoken'] = researcherInfo.txt_token;
    config.headers.get['authtoken'] = researcherInfo.txt_token;
    return config;
  } else {
    if (config.url == "/RoleVerificationCode") {
      return config;
    } else {
      sessionStorage.clear();
      location.replace("/login");
      return false;
    }
  }
}, err => {
  console.error(err);
  Promise.reject(err);
});

// 响应拦截器
service.interceptors.response.use(res => {
  if (res.data.code == 1007) {
    sessionStorage.clear();
    location.replace("/login");
  } else {
    return res.data;
  }
}, err => {
  console.error(err);
  Promise.reject(err);
}, err => {
  console.error(err);
  Promise.reject(err);
});

export default service;