<template>
<el-dialog v-model="visible" title="修改密码" :before-close="close" custom-class="change-psw-dialog">
  <div class="cpd-container" v-loading="loading">
    <div class="cpdc-item">
      <div class="cpdci-title">原密码</div>
      <el-input placeholder="请输入原始密码" v-model.trim="form.oldpsw"></el-input>
      <div class="cpdci-hint" v-if="oldHintShow">原密码输入错误</div>
    </div>
    <div class="cpdc-item">
      <div class="cpdci-title">新密码</div>
      <el-input placeholder="请输入新密码" v-model.trim="form.newpsw"></el-input>
      <div class="cpdci-hint" v-if="newHintShow">8-20位，必须含有数字、字母及特殊字符</div>
    </div>
    <div class="cpdc-item">
      <div class="cpdci-title">再次输入新密码</div>
      <el-input placeholder="请再次输入新密码" v-model.trim="form.confirmPsw"></el-input>
      <div class="cpdci-hint" v-if="confirmHintShow">两次密码输入不一致</div>
    </div>
    <div class="change-psw-hit" v-if="firstOrChange!=''">{{ firstOrChange }}</div>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close">取 消</el-button>
      <el-button :disabled="btnDisabled" @click="confirm" class="pcd-btn">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 修改密码 dialog
import { sha256 } from 'js-sha256'
import { useStore } from "vuex";
import { getChangPwd } from "@/api/apis.js";
import { ElMessage } from 'element-plus';
import { reactive, toRefs, computed } from "vue";
export default {
  setup(prop, ctx) {
    const store = useStore();
    const state = reactive({
      visible: true,
      researcherInfo: computed(() => store.getters.researcherInfo),
      form: {
        oldpsw: "",
        newpsw: "",
        confirmPsw: ""
      },
      loading: false,
      firstOrChange: computed(() => {
        if (state.researcherInfo.is_first_login == null) {
          return "首次登录请修改默认密码";
        } else if (state.researcherInfo.is_change == 1) {
          return "密码即将到期，请修改密码";
        } else {
          return "";
        }
      }),
      oldHintShow: computed(() => {
        if ((state.form.oldpsw || "") != "") {
          if (state.researcherInfo.password != state.form.oldpsw) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }),
      newHintShow: computed(() => {
        if ((state.form.newpsw || "") != "") {
          //  判断 字符长度 和 格式
          // let reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$");
          let reg = /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\W])(?=.*[\S])^[0-9A-Za-z\S]{8,20}$/g;
          if (reg.test(state.form.newpsw)) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
      confirmHintShow: computed(() => {
        if ((state.form.confirmPsw || "") != "") {
          if (state.form.newpsw == state.form.confirmPsw) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }),
      btnDisabled: computed(() => {
        // 不存在空值
        if (Object.values(state.form).findIndex(t => (t || "") == "") == -1) {
          // 不存在 不符合条件的数据
          if ([state.oldHintShow, state.newHintShow, state.confirmHintShow].findIndex(t => t == true) == -1) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    })

    const close = () => {
      ctx.emit("close");
    }

    // 提交
    const confirm = () => {
      state.loading = true;
      getChangPwd({
        t_user_id: state.researcherInfo.t_researcher_id,
        txt_old_pwd: sha256(state.form.oldpsw),
        txt_new_pwd: sha256(state.form.newpsw),
        txt_confirm_pwd: sha256(state.form.confirmPsw),
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("修改成功，请重新登录");
          setTimeout(() => {
            sessionStorage.removeItem("researcherInfo");
            state.loading = false;
            location.reload();
          }, 1000)
        } else {
          state.loading = false;
          ElMessage.error("修改失败" + response.msg);
        }
      }).catch(e => {
        console.error(e);
      })
    };

    return {
      ...toRefs(state),
      close,
      confirm
    }
  }
}
</script>

<style lang="scss">
.change-psw-dialog {
  width: 500px !important;
  height: calc(100% - 50vh) !important;

  .cpd-container {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 26px;
    position: relative;

    .change-psw-hit {
      position: absolute;
      bottom: 0%;
      color: rgb(235, 21, 21);
    }

    .cpdc-item {
      margin-bottom: 16px;

      .cpdci-title {
        font-size: 16px;
        margin-bottom: 6px;
      }

      .el-input {
        .el-input__wrapper {
          border-radius: 18px;
        }
      }

      .cpdci-hint {
        margin-top: 4px;
        font-size: 12px;
        color: rgb(206, 26, 26);
      }
    }
  }

  .dialog-footer {
    .el-button {
      font-weight: bold;
      border-radius: 16px;
    }

    .pcd-btn {
      color: #ffffff;
      background-color: #8a88c3;

      &:hover {
        color: #ffffff;
        background-color: rgba($color: #8a88c3, $alpha: 0.8);
      }
    }
  }
}
</style>
