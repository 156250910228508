import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ 'views/login/index.vue'),
    meta: { title: "登录" }
  },
  {
    path: "/",
    redirect: "/pending"
  },
  {
    path: "/pending",
    name: "Pending",
    component: () => import(/* webpackChunkName: "pending" */ 'views/cost/pending.vue'),
    meta: { title: "待处理申请", father: "cost" }
  },
  {
    path: '/processed',
    name: 'Processed',
    component: () => import(/* webpackChunkName: "processed" */ 'views/cost/processed.vue'),
    meta: { title: "已处理申请", father: "cost" }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由导航首页
router.beforeEach((to, from, next) => {
  // 跳过开发环境
  // todo
  if (process.env.NODE_ENV == "development") {
    next();
  } else {
    let researcherInfo = JSON.parse(sessionStorage.getItem("researcherInfo"));
    // 如果当前不是登录页
    if (to.path != "/login") {
      // 没有用户信息，跳转至登录页
      if (!researcherInfo) {
        next("/login");
      } else {
        // 有 用户信息  正常跳转页面
        next();
      }
    } else {
      // 是登录页 继续
      next();
    }
  }
})

window.router = router;

export default router
